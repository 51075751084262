import { defineComponent, onBeforeUnmount, onMounted, ref, Ref, getCurrentInstance } from '@vue/composition-api';

interface WArea {
	x: number | null;
	y: number | null;
	max: number;
}
interface Dot extends WArea {
	x: number;
	y: number;
	xa: number;
	ya: number;
}
type TimeoutT = number | undefined;
let tAnimate: TimeoutT = undefined;
let tReqAnimateFrame: TimeoutT | void = undefined;
export default defineComponent({
	setup() {
		const { proxy } = getCurrentInstance()!;
		const t = proxy.$t.bind(proxy);

		//文字
		const labText: Ref<any> = ref({
			title: '实验室',
			description: '好看的皮囊千篇一律，有趣的灵魂万里挑一',
		});
		//动0画
		const canvas: Ref<HTMLCanvasElement | null> = ref(null);
		const eventMousemove = () => {
			//
		};
		const eventMouseout = () => {
			//
		};
		const canvasConfig = {
			dotLength: 80, //粒子个数
			dotSize: 1, //粒子大小
			dotColor: '',
			lineColor: [255, 144, 255], //线条颜色rgb
			mouseMax: 20000, //鼠标距离多远触发线条
			dotMax: 6000, //线距离多远触发线条
		};
		const createCanvas = (canvas: HTMLCanvasElement) => {
			const ctx = canvas.getContext('2d');
			if (ctx === null) {
				return;
			}
			//监听窗口大小改变
			const resize = () => {
				if (canvas.parentNode && typeof canvas.parentNode === 'object') {
					canvas.width = canvas.parentNode.clientWidth;
					canvas.height = canvas.parentNode.clientHeight;
					//console.log(`w:${canvas.width},h:${canvas.height}`);
				}
			};
			resize();
			window.addEventListener('resize', resize);
			onBeforeUnmount(() => {
				window.removeEventListener('resize', resize);
			});

			//定义帧
			const RAF = (() => {
				const res =
					window.requestAnimationFrame ||
					(window as any).webkitRequestAnimationFrame ||
					(window as any).mozRequestAnimationFrame ||
					(window as any).oRequestAnimationFrame ||
					(window as any).msRequestAnimationFrame ||
					function (callback) {
						tAnimate = window.setTimeout(callback, 1000 / 60);
					};
				return res;
			})();

			//鼠标活动时获取鼠标坐标
			const warea: WArea = {
				x: null,
				y: null,
				max: canvasConfig.mouseMax || 20000,
			};
			const eventMousemove = (event: MouseEvent) => {
				//event ||= window.event;
				const position = canvas?.getBoundingClientRect() || {};
				warea.x = event.clientX - (position.x || 0);
				warea.y = event.clientY - (position.y || 0);
			};
			const eventMouseout = (event: MouseEvent) => {
				warea.x = null;
				warea.y = null;
			};
			window.addEventListener('mousemove', eventMousemove);
			window.addEventListener('mouseout', eventMouseout);
			onBeforeUnmount(() => {
				window.removeEventListener('mousemove', eventMousemove);
				window.removeEventListener('mouseout', eventMouseout);
			});

			//添加粒子
			const dots: Dot[] = [];
			for (let i = 0; i < (canvasConfig.dotLength || 50); i++) {
				const x = Math.random() * canvas.width;
				const y = Math.random() * canvas.height;
				dots.push({
					x,
					y,
					xa: Math.random() * 2 - 1,
					ya: Math.random() * 2 - 1,
					max: canvasConfig.dotMax || 6000,
				});
			}
			//console.log(dots);

			//定义动画每一帧的逻辑
			const animate = () => {
				ctx?.clearRect(0, 0, canvas.width, canvas.height);
				const ndots = [warea].concat(dots);
				dots.forEach(dot => {
					//粒子位移
					dot.x += dot.xa;
					dot.y += dot.ya;
					//遇到边界加速反向
					dot.xa *= dot.x > canvas.width || dot.x < 0 ? -1 : 1;
					dot.ya *= dot.y > canvas.height || dot.y < 0 ? -1 : 1;
					//绘制点
					ctx?.fillRect(dot.x - (canvasConfig.dotSize / 2 || 0.5), dot.y - (canvasConfig.dotSize / 2 || 0.5), canvasConfig.dotSize || 1, canvasConfig.dotSize || 1);
					//循环对比粒子之间的距离
					for (let i = 0; i < ndots.length; i++) {
						const d2 = ndots[i];
						if (dot === d2 || d2.x === null || d2.y === null) {
							continue;
						}
						const xc = dot.x - d2.x;
						const yc = dot.y - d2.y;
						//两个粒子之间的距离
						const dis = xc * xc + yc * yc;
						//距离比
						let ratio;
						// 如果两个粒子之间的距离 小于粒子对象的max值，则在两个粒子间画线
						if (dis < d2.max) {
							//如果是鼠标 ，则让粒子向鼠标位置移动
							if (d2 === warea && dis > d2.max / 2) {
								dot.x -= xc * 0.01;
								dot.y -= yc * 0.01;
							}
							//计算距离比
							ratio = (d2.max - dis) / d2.max;
							//画线
							ctx?.beginPath();
							ctx.lineWidth = ratio / 2;
							ctx.strokeStyle = `rgba(${canvasConfig.lineColor.length === 3 ? canvasConfig.lineColor.join(',') : '30,144,255'},${ratio + 0.2})`;
							ctx?.moveTo(dot.x, dot.y);
							ctx?.lineTo(d2.x, d2.y);
							ctx?.stroke();
						}
					}
				});
				tReqAnimateFrame = RAF(animate);
			};
			//延迟开启动画
			const t = setTimeout(() => {
				animate();
			}, 100);
			onBeforeUnmount(() => {
				clearTimeout(t);
			});
		};
		const listenerAdder = () => {
			//
		};
		const listenerRemover = () => {
			//
		};
		onMounted(() => {
			if (canvas.value !== null) {
				createCanvas(canvas.value);
				listenerAdder();
			}
		});
		onBeforeUnmount(() => {
			listenerRemover();
			//尝试关闭动画
			try {
				const cancelAnimationFrame = window.cancelAnimationFrame || (window as any).mozCancelAnimationFrame;
				if (typeof tReqAnimateFrame === 'number') {
					cancelAnimationFrame(tReqAnimateFrame);
				}
			} catch (e) {
				//
			}
			//针对不兼容requestAnimationFrame的浏览器
			clearTimeout(tAnimate);
		});
		return { canvas, labText, t };
	},
});
