import { defineComponent, ref, getCurrentInstance } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import PageNodeMain from '@/components/PageNodeMain';
import PageNodeSub from '@/components/PageNodeSub';
import Card from '@/components/Card';

import LabHeader from '@/components/Article/LabHeader';

import iconEffect from '@/assets/image/lab-m-effect.png';
import iconProject from '@/assets/image/lab-m-project.png';
import iconSponsor from '@/assets/image/lab-m-sponsor.png';
import iconTool from '@/assets/image/lab-m-tool.png';

import { VueConstructor } from 'vue';

interface Nav {
	key: string;
	title: string;
	icon?: string | VueConstructor<Vue>;
}
export default defineComponent({
	name: 'Lab',
	components: { PageNodeMain, PageNodeSub, Card, LabHeader },
	setup(props) {
		const proxy = getCurrentInstance()?.proxy || {
			$t() {
				/**/
			},
		};
		const t = proxy.$t.bind(proxy);
		const nav: Nav[] = [
			{
				key: 'project',
				title: '实验项目',
				icon: iconProject,
			},
			{
				key: 'effect',
				title: '网页特效',
				icon: iconEffect,
			},
			{
				key: 'tool',
				title: 'Web工具箱',
				icon: iconTool,
			},
			{
				key: 'interface',
				title: 'API接口',
				icon: iconSponsor,
			},
		];
		return { nav, t };
	},
});
